import React from 'react'
import "./Project.css"
const Project = () => {
  return (
    <div className='project'>
       Comming Soon...
    </div>
  )
}

export default Project
