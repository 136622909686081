import React from 'react'
import { NavLink } from 'react-router-dom'
import { RiInstagramLine } from "react-icons/ri";
import { FaSquareTwitter } from "react-icons/fa6";
import { RiLinkedinBoxFill } from "react-icons/ri";
import "./Footer.css"
const Footer = () => {
  return (
    <div className='footer'>
    <div className="footer_cont_box">
        <div className="footer_box1">
            <h3>Quick Links</h3>
            <NavLink to="/">Home</NavLink>
            <a href="/#service_container">Service</a>
            <NavLink to="/aboutus">About Us</NavLink>
            <NavLink to="/projects">Projects</NavLink>
            <NavLink to="/blog">Blogs</NavLink>
            <a href="/#contact_box">Contact Us</a>
        </div>

        <div className="footer_box2">
            <div className="footer_box21">
                <div className="footer_contactBox">
                    <h3>Contact Us</h3>
                    <p>+91 9340648978</p>
                    <p>netlest@gmail.com</p>
                    <p>Smriti Nagar, Bhilai, CG.</p>
                </div>

                <div className="footer_socialMedia">
                <h3>Follow Us On</h3>
                <a href='https://www.instagram.com/netlest.in?igsh=NmN1bzY1OTBtdHQ4&utm_source=qr' target='_blank'><RiInstagramLine size={40}/></a>
                <a href='https://twitter.com/netlest7' target='_blank'><FaSquareTwitter size={40}/></a>
                <a href='https://www.linkedin.com/in/netlest-software-solutions-6b1a372b8/' target='_blank'><RiLinkedinBoxFill size={40}/></a> 
                </div>
            </div>

            <div className="footer_box22">
                <p>Terms & Conditions</p>
                <p>Privacy Policy</p>
                <p>©2024. All rights reserved.</p>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Footer

