import React from 'react'
import "./Offer.css"
import ServiceBox from '../ServiceBox/ServiceBox'
import ios from "../../assets/ios.png"
import android from "../../assets/android.png"
import web from "../../assets/webDev.png"
import uiux from "../../assets/uiux.png"
import seo from "../../assets/seo.png"
const Offer = () => {

  return (
    <div id='service_container'>
      <h3>We Offer A Wide Range Of Services.</h3>
      <div className="offer-tiles">
      <ServiceBox img={web} title="Web Development" para="Crafting user-friendly web apps that drive results. Let's talk!"/>
      <ServiceBox img={ios} title="iOS Development" para="From iPhone to iPad, we build native iOS apps that engage and convert."/>
      <ServiceBox img={uiux} title="UI/UX Design" para="Making apps people love. We design for impact, not just looks."/>
      <ServiceBox img={android} title="Android Development" para="Reach millions on the world's most popular mobile platform. Build with Android."/>
      <ServiceBox img={seo} title="Search Engine Optimization" para="Get found, get chosen. Powerful SEO that drives organic traffic and growth."/>
      </div>

    </div>
  )
}

export default Offer
