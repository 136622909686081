import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import img from "../../assets/mainimage.png"
import img2 from "../../assets/Programmer working with code on a laptop.png"
import Contact from '../../Components/Contact/Contact'
import Footer from '../../Components/Footer/Footer'
import "./About.css"

const About = () => {
  return <main id='about'>
            <Navbar/>
             <section className='section1'>
                <div className="section1_content">
                    <h1>About Us</h1>
                    <hr/>
                    <p>Welcome to Netlest, your premier full-service software development and digital agency. 
                        With a team of experienced and passionate professionals, we strive to build amazing software projects for our clients, 
                        providing exceptional value through design, code, and execution. Our services cover a wide range of software development and digital
                        marketing solutions, including website and mobile app development, custom software development, e-commerce solutions, 
                        digital marketing, and more.
                    </p>
                </div>
                <img src={img} alt='image'/>
            </section>

            <section className='section2'>
                <p>
                At Netlest, we believe that technology is a powerful tool that can transform businesses and revolutionize industries. Our mission is to harness the latest technological advancements and deliver innovative solutions to our clients, helping them stay ahead in the digital landscape.
                Our services encompass the entire software development lifecycle, from conceptualization to design, development, testing, and deployment. We specialize in creating custom software solutions tailored to our clients' unique needs, leveraging cutting-edge technologies and best practices to deliver high-quality and scalable applications.
                Our team of skilled designers, developers, and project managers work closely with our clients to understand their vision, goals, and requirements. We believe in collaborative partnerships, and our clients are involved in every step of the development process to ensure that their software projects align with their strategic objectives.

                </p>
            </section>


            <section className='section3'>
                <img src={img2} alt="img" />
             <p>
             At Netlest, we are committed to delivering exceptional results. Our team is dedicated to producing software solutions that are not only functional and reliable, but also aesthetically pleasing and user-friendly. We understand that a well-designed software application can significantly impact user experience and drive business success.
In addition to our software development expertise, we also offer digital marketing services to help our clients establish a strong online presence and reach their target audience. From search engine optimization (SEO) to social media marketing, our digital marketing strategies are designed to drive traffic, generate leads, and increase brand visibility.

             </p>
            </section>

            <section className='section4'>
                <p>
                Choose Netlest as your software development and digital agency partner and experience the difference of working with a team that is passionate about creating exceptional software solutions. Contact us today to discuss your software needs and let us bring your vision to life.
                </p>
            </section>

            <Contact/>

            <Footer/>
        </main>
}

export default About