import React, { useState } from 'react'
import "./Contact.css"
import image from '../../assets/Girl asks a question to the support service and the operator answers her question.svg'
import toast, { Toaster } from "react-hot-toast";
import {db} from "../../config/firebase";
import { addDoc, collection } from 'firebase/firestore';

const Contact = () => {
   const dbCollectionRef = collection(db,"userQuery");

    const [userDetails,setUserDetails] = useState({
        name:"",
        company:"",
        email:"",
        description:""
    })

    const clientDetails = async(e) =>{
      e.preventDefault();
      try{

        await addDoc(dbCollectionRef,{name: userDetails.name,email: userDetails.email,
        company: userDetails.company , description : userDetails.description
        })

        setUserDetails({
          name:"",
          company:"",
          email:"",
          description:""
      })
        toast.success("Submitted!")

        
        

      }catch(error){
        console.log(error);
      }
    }

  return (
    <div id='contact_box'>
        <div className="gray_box">

        <div className="box1">
        <Toaster/>

        <h3>Have An Idea?<br/> Let's Bring It To Life Today.</h3>

        <form onSubmit={clientDetails}>
            <div className="fullNameBox">
            <label htmlFor='name'>Full Name</label>
            <input required type='text' placeholder='Enter your full name' value={userDetails.name} name='name' onChange={(e)=>setUserDetails((prev)=>({
                ...prev,
              [e.target.name] : e.target.value
            }))}/>
            </div>

            <div className="companyNameBox">
            <label htmlFor='company'>Company</label>
            <input required type='text' placeholder='Enter your company name' value={userDetails.company} name='company' onChange={(e)=>setUserDetails((prev)=>({
                ...prev,
              [e.target.name] : e.target.value
            }))}/>
            </div>

            <div className="emailNameBox">
            <label htmlFor='email'>Email ID</label>
            <input required type='text' placeholder='Enter your email id' value={userDetails.email} name='email' onChange={(e)=>setUserDetails((prev)=>({
                ...prev,
              [e.target.name] : e.target.value
            }))}/>
            </div>

            <div className="descriptionBox">
            <label htmlFor='description'>Description</label>
            <textarea required placeholder='Write your message here' name='description' value={userDetails.description} onChange={(e)=>setUserDetails((prev)=>({
                ...prev,
              [e.target.name] : e.target.value
            }))}/>
            </div>

            <div className='btnHolder'>
            <button type='submit'>Submit</button>

            </div>
        </form>
        </div>
        <div className="box2">
            <img src={image} alt='image'/>
        </div>
        </div>

        
    </div>
  )
}

export default Contact