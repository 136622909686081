import {BrowserRouter as Router , Route,Routes} from "react-router-dom"
import Home from "./Pages/Home/Home";
import Blog from "./Pages/Blog/Blog";
import About from "./Pages/About/About";
import './App.css'
import Project from "./Pages/Project/Project";
function App() {
  return <Router>
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/blog" element={<Blog/>}/>
      <Route path="/aboutus" element={<About/>}/>
      <Route path="/projects" element={<Project/>}/>
    </Routes>

  </Router>

}

export default App;
