import React from 'react'
import "./Blog.css"
const Blog = () => {
  return (
    <div className='blog'>
       Comming Soon...
    </div>
  )
}

export default Blog
