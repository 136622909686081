import React from 'react'
import Navbar from "../../Components/Navbar/Navbar";
import "./Home.css"
import mainImage from "../../assets/mainimage.png"
import Offer from '../../Components/Offer/Offer';
import Contact from '../../Components/Contact/Contact';
import Footer from '../../Components/Footer/Footer';
const Home = () => {
  return (
    <main>
       <Navbar/>
       <div className='main-section'>
        <div className='main-box1'>
          <h2>Build Your Awesome Platform</h2>
          <p>Netlest is a full-service software development & digital agency. We build amazing software projects for our clients
            and provide value through design, code, & execution.</p>
          <button className='btn'>Our Services</button>
        </div>
        <div className='main-box2'>
          <img src={mainImage}/>
        </div>
       </div>
       {/* offer section starts */}
       <Offer/>
       {/* offer section ends */}

       {/* contact section starts */}

       <Contact/>
       {/* contact section ends */}


      {/* Footer */}
    <Footer/>

    </main>
  )
}

export default Home
