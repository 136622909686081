import React from 'react'
import "./ServiceBox.css"

const ServiceBox = ({img,title,para}) => {
  return (
    <div className='serviceBox'>
      <img src={img} alt='img'/>
      <h3>{title}</h3>
      <p>{para}</p>
    </div>
  )
}

export default ServiceBox
