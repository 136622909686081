import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore} from "firebase/firestore"


const firebaseConfig = {
  apiKey: "AIzaSyA0YQHFJq1_YAQmVdbnKB4b07emGxtRIiQ",
  authDomain: "netlest-3ea9b.firebaseapp.com",
  projectId: "netlest-3ea9b",
  storageBucket: "netlest-3ea9b.appspot.com",
  messagingSenderId: "216768866572",
  appId: "1:216768866572:web:9fcd6998ba1478e91f4129",
  measurementId: "G-74D3G45HDK"
};

// Initialize Firebasè
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const db = getFirestore(app);
