import React, { useRef } from 'react'
import "./Navbar.css"
import NavbarLogo from "../../assets/companyMainLogo.png"
import {FaBars,FaTimes} from "react-icons/fa"
import { NavLink } from 'react-router-dom'
const Navbar = () => {

  const navRef = useRef();

  const showNavbar = () =>{
   navRef.current.classList.toggle("responsive_nav")
  }
  return <nav className='navbar'>
    <div className='logoContainer'>
        <img src={NavbarLogo} alt='logo'/>
        <h2>Netlest</h2>
    </div>

    <div className='navbar-items-container' ref={navRef}>
        <NavLink to="/" onClick={showNavbar}>Home</NavLink>
        <a href='/#service_container' onClick={showNavbar}>Service</a>
        <NavLink to="/aboutus" onClick={showNavbar}>About Us</NavLink>
        <NavLink to="/projects" onClick={showNavbar}>Projects</NavLink>
        <NavLink to="/blog" onClick={showNavbar}>Blogs</NavLink>
        <button>
        <a href='/#contact_box'>Contact Us</a>
        </button>
        <button className='nav-btn close-btn' onClick={showNavbar}>
            <FaTimes size={25}/>
        </button>
    </div>
    <button className='nav-btn' onClick={showNavbar}>
        <FaBars size={25}/>
    </button>
  </nav>
}

export default Navbar
